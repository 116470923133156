import * as React from "react"
import styled from "styled-components"
import { colours } from "../global/colours"

const Container = styled.form`
    display: flex;
    flex-direction: column;
    border: solid 1px ${colours.navy};
    border-top: none;
    input, textarea {
      background: transparent;
      padding: 10px;
      border: solid 1px ${colours.navy};
      color: ${colours.navy};
      border-left: none;
      border-right: none;
    }
    input[type=text] {
      border-bottom: none;
    }
    textarea {
      min-height: 200px;
      margin-top: 2px;
    }
    input[type=submit] {
      background: ${colours.navy};
      color: ${colours.white};
      transition: 0.4s;
      border: solid 2px ${colours.navy};
      &:hover {
        background: transparent;
        color: ${colours.navy};
      }
    }
`

export const Form = () => {
  return (
    <Container
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
    >
      <input type="hidden" name="bot-field"/>
      <input type="hidden" name="form-name" value="contact" />
      <input type="text" name='name' placeholder='Name' required={true}/>
      <input type="email" name='email' placeholder='Email' required={true}/>
      <textarea name='message' placeholder='Message' id='form-message' required={true}/>
      <input type="submit" value='Send'/>
    </Container>
  )
}
