import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import { colours } from "../global/colours"
import { SocialMediaIcons } from "./socialMediaIcons"

const Logo = styled.div`
  position: relative;
  width: 210px;
  isolation: isolate;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;
  background: transparent;
  &:hover:before {
    transform: translate(0px, 0px);
  }
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    left: 0px;
    height: 46px;
    width: 185px;
    transform: translate(22px, 34px);
    background: rgb(253, 165, 164);
    transition: transform 0.8s ease 0s;
  }
  h1 {
    color: ${colours.black};
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
    @supports (mix-blend-mode: color-dodge) {
      mix-blend-mode: color-dodge;
      color: rgb(253, 165, 164);
    }
  }
  p {
    font-size: 14px;
    color: ${colours.black};
    text-align: right;
    margin: 0px;
    @supports (mix-blend-mode: color-dodge) {
      mix-blend-mode: color-dodge;
      color: rgb(253, 165, 164);
    }
  }
`

const Links = styled.div`
  & > * {
    font-weight: bold;
    padding: 10px;
    color: ${({ dark }: { dark: boolean }) =>
      dark ? colours.white : colours.navy};
    transition: 0.4s;
    display: inline;
    &:hover {
      color: ${({ dark }: { dark: boolean }) =>
        dark ? colours.navy : colours.peach};
    }
    @media (max-width: 700px) {
      color: ${colours.navy};
    }
  }
`

const HeaderWrapper = styled.header`
  padding: 20px;
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    border-bottom: solid 1px ${colours.blue};
    .content {
      flex-direction: column;
      align-items: center;
    }
    .socialMediaIcons {
      display: none;
    }
  }
`

export const Header = ({ dark }: { dark: boolean }) => {
  const [footer, setFooter] = React.useState(null)
  React.useLayoutEffect(() => {
    setFooter(document.getElementById("footer"))
  }, [])
  return (
    <HeaderWrapper>
      <div className="content">
        <Logo>
          <Link to="/">
            <h1>Louise</h1>
            <h1>Moxhay</h1>
            <p>designer & developer</p>
          </Link>
        </Logo>
        <div>
          <SocialMediaIcons dark={dark} />
          <Links dark={dark}>
            <Link
              to={"/portfolio"}
              activeStyle={{ color: dark ? colours.navy : colours.peach }}
              partiallyActive={true}
            >
              Portfolio
            </Link>
            <p onClick={() => footer.scrollIntoView({ behavior: "smooth" })}>
              Contact
            </p>
          </Links>
        </div>
      </div>
    </HeaderWrapper>
  )
}
