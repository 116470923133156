import * as React from "react"
import styled from "styled-components"
// @ts-ignore
import MediumSvg from "../assets/medium.svg"
// @ts-ignore
import InstagramSvg from "../assets/instagram.svg"
// @ts-ignore
import CodepenSvg from "../assets/codepen.svg"
// @ts-ignore
import GithubSvg from "../assets/github.svg"
// @ts-ignore
import LinkedinSvg from "../assets/linkedin.svg"
import { colours } from "../global/colours"

const Container = styled.div`
  display: flex;
  width: max-content;
  margin-left: auto;
  margin-bottom: 20px;
  svg {
    height: 25px;
    padding: 3px;
    fill: ${({dark}: {dark: boolean}) => dark ? colours.white : colours.navy};
    transition: fill 0.4s;
    &:hover {
      fill: ${({dark}: {dark: boolean}) => dark ? colours.navy : colours.peach};
    }
    @media (max-width: 700px) {
      fill: ${colours.navy};
    }
  }
`

export const SocialMediaIcons = ({dark}: {dark: boolean}) => (
  <Container dark={dark} className='socialMediaIcons'>
      <a href="https://www.github.com/louMoxy">
        <GithubSvg />
      </a>
    <a href="https://www.linkedin.com/in/louisemoxhay/">
      <LinkedinSvg />
    </a>
    <a href="https://www.medium.com/@louisemoxy">
      <MediumSvg />
    </a>
    <a href="https://www.codepen.io/louisemoxy">
      <CodepenSvg />
    </a>
    <a href="https://www.instagram.com/louisemoxy/">
      <InstagramSvg />
    </a>
  </Container>
)
