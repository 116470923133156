import React from "react"
import PropTypes from "prop-types"
import Blob from "../assets/blob.svg"
import styled from "styled-components"
import "./layout.css"
import { Header } from "./header"
import { Footer } from "./footer"

const HeaderBlob = styled(Blob)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  width: 60%;
  height: auto;
  @media (max-width: 700px) {
    display: none;
  }
`

const Layout = ({ children, showBlob = true }) => {
  return (
    <>
      <div id="mainContent">
        {showBlob && <HeaderBlob />}
        <Header dark={showBlob} />
        <div>
          <main>{children}</main>
        </div>
        <div id="footer"></div>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
