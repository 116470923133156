import * as React from "react"
import styled from "styled-components"
import { colours } from "../global/colours"
// @ts-ignore
import FooterSvg from '../assets/footer.svg';
import { Form } from "./contactForm"
import { SocialMediaIcons } from "./socialMediaIcons"

export const Footer = () => {
  return (
    <Container>
      <Columns className='content'>
        <div>
          <h4>Want to work together?</h4>
          <Form/>
          <SocialMediaIcons dark={false}/>
        </div>
        <FooterSvg/>
      </Columns>
      <small>© {new Date().getFullYear()} Louise Moxhay</small>
    </Container>
  )
}

const Columns = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  h4 {
    color: ${colours.white};
    font-weight: bold;
    font-size: 1.8em;
  }
  & > * {
    width: 40%;
    min-width: 250px;
  }
  margin-bottom: 30px;
`

const Container = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${colours.blue};
  display: grid;
  place-items: center;
  padding: 140px 30px 20px ;
  margin-top: -30px;
  small {
    color: ${colours.navy};
    opacity: 0.8;
    font
  }
`
